import React from 'react';
// import { List } from '@prosolve/common-components-ui'

export default () => {

  // const list = [
  //   "Taking a boring topic and creating ideas for an immersive experience that will engage students.",
  //   "Crafting questions that can't be googled to help build high-order thinking skills.",
  //   "Turn multiple-choice test questions into a performance task so students can show how well they apply their knowledge, skills and abilities.",
  //   "Generating protocols to support collaborative group work to build human-centric skills in all your students.",
  //   "Turning an independent activity into a collaborative discovery to create a dynamic and inclusive learning environment.",
  //   "And so much more!"
  // ]

  return (
    <div 
      style={{
        padding: '20px',
        width: '835px'
        // width: '780px'
      }}
    >
      <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '20px' }}>
        {"Getting started is easy! Just ask me a question about experiential learning and see what happens. We can chat, explore concepts together, refine ideas, and, best of all, you will be learning by doing. I am here to help you make an impact and support you in rediscovering the joy and spark of being an educator."}
      </div>
      {/* <div style={{ fontSize: '20px' }}>
        {"Here are some of the ways my LXP model can help you:"}
      </div> */}
      {/* <List>
        {
          list.map(item => 
            <li key={item}>
              {item}
            </li>
          )
        }
      </List> */}
    </div>
  )
}