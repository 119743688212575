import React, { useState } from "react";
// import { Accordion } from '@prosolve/common-components-ui'
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { List } from '@prosolve/common-components-ui'


export default ({
  title,
  isSmallView,
  data = []
}) => {

  let titleBackround;
  if (title === 'Immersive Experiences') {
    titleBackround = `linear-gradient(
      90deg,
      rgba(220, 126, 57, 1) 25%,
      rgba(194, 70, 153, 1) 100%
    )`
  } else if (title === 'Inquiry Through Exploration') {
    titleBackround = `linear-gradient(
      90deg,
      rgba(194, 70, 153, 1) 25%,
      rgba(68, 195, 212, 1) 100%
    )`
  } else if (title === 'Authentic Application') {
    titleBackround = `linear-gradient(
      90deg,
      rgba(68, 195, 212, 1) 25%,
      rgba(83, 211, 119, 1) 100%
    )`
  } else {
    titleBackround = `linear-gradient(
      90deg,
      rgba(83, 211, 119, 1) 25%,
      rgba(220, 126, 57, 1) 100%
    )`
  }

  const [expandedIndex, setExpandedIndex] = useState(-1)
  
  return (
    <div style={{
      border: '1px solid #dedede',
      borderRadius: '5px',
      width: isSmallView ? '100%' : '400px'
    }}>
      <div 
        style={{
          padding: '10px',
          color: '#ffffff',
          fontSize: '20px',
          fontWeight: 'bold',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          background: titleBackround
        }}
      >
        {title}
      </div>
      {
        data.map(({ question = '', answers = [] }, index) => {
          return (
            <div
              key={question}
              style={{
                padding: '5px 0px 0px 0px',
                borderBottom: index === data.length -1 ? 'none' : '1px solid #dedede'
              }}
            >
              {/* <Accordion
                title={<div style={{ marginLeft: '20px', fontWeight: 'bold' }}>{question}</div>}
                headerExpands={true}
                style={{ padding: '5px' }}
                expandedStyle={{ padding: '10px 20px 0px 20px', borderTop: '1px solid #dedede' }}
                defaultOpen={expandedIndex === index}
                onExpandedChange={(isExpanded) => { console.log('set'); setExpandedIndex(isExpanded === true ? index : -1) }}
              >
                <div>
                  <List style={{
                    margin: '0px',
                    paddingLeft: '10px'
                  }}>
                  {
                    answers.map((answer) => {
                      return (
                        <li key={answer} style={{ paddingBottom: '5px'}}>
                          {answer}
                        </li>
                      )
                    })
                  }
                  </List>
                </div>
              </Accordion> */}

              {/* 
                Since we wanted to close other accordians in the group after opening one, the component design started to become far enough outside the core
                component I essentially rebuilt a custom accordian here. We may want to eventually turn this into another common component thats called AccordianGroup
               */}
              <div style={{
                display: 'flex',
                flexDirection: 'column'
              }}>
                <div
                  onClick={() => setExpandedIndex(expandedIndex === index ? -1 : index)}
                  style={{
                    display: 'flex',
                    padding: '5px',
                    cursor: 'pointer',
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <div style={{ marginLeft: '20px', fontWeight: 'bold' }}>{question}</div>
                  </div>
                  <div 
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() => setExpandedIndex(expandedIndex === index ? -1 : index)}
                  >
                    {
                      expandedIndex === index 
                      ? <FaChevronUp style={{ color: '#44ACE1' }} />
                      : <FaChevronDown style={{ color: '#b1b5c4' }} />
                    }
                  </div>
                </div>
                <div style={{ 
                  display: expandedIndex === index ? 'block' : 'none',
                  padding: '10px 20px 0px 20px', 
                  borderTop: '1px solid #dedede'
                }}>
                  <div>
                    <List style={{
                      margin: '0px',
                      paddingLeft: '10px'
                    }}>
                    {
                      answers.map((answer) => {
                        return (
                          <li key={answer} style={{ paddingBottom: '5px'}}>
                            {answer}
                          </li>
                        )
                      })
                    }
                    </List>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}