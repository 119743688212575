import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginPage from "./LoginPage";
import RouteErrorPage from './RouteErrorPage';
import Cookies from 'js-cookie';

const PrivateRoute = ({ element: Component, ...routeProps }) => {
  const isAuthorizedCookie = Cookies.get('isAuthorized');
  return (isAuthorizedCookie && isAuthorizedCookie === 'true') ? <Component {...routeProps} /> : <LoginPage />;
};

const PublicRoute = ({ element: Component, ...routeProps }) => {
  return <Component {...routeProps} />;
};

export default ({
  routes = []
}) => {
  const parseRoute = (route) => {
    return route.map((routeObj) => {
      const {
        path,
        isPrivate,
      } = routeObj;
      const parsedRouteObj = { ...routeObj }
      if (path === '/') {
        parsedRouteObj.errorElement = <RouteErrorPage />;
      }

      if (isPrivate) {
        parsedRouteObj.element = <PrivateRoute {...routeObj} />;
      } else {
        parsedRouteObj.element = <PublicRoute {...routeObj} />;
      }
      
      return parsedRouteObj
    })
  }

  const router = createBrowserRouter(parseRoute(routes));

  return <RouterProvider router={router} />
}