import React from 'react';
import useMediaQuery from '../hooks/useMediaQuery';
// import HeroBanner from '../images/Hero-Banner.png';
// import HeroBannerBackground from  '../images/teal-hex-bkg-curved-bottom.png';
import TopBannerImage from '../images/teal-hex-bkg.png'
import BigAriaImage from '../images/big aria.png';

export default ({
  isMobileView
}) => {
  const smallMobile = useMediaQuery('(max-width: 380px)');
  const isSmallView = useMediaQuery('(max-width: 550px)');
  const isMediumView = useMediaQuery('(max-width: 770px)');

  // const needsTopShift = useMediaQuery('(max-width: 710px)');

  let view;
  if (smallMobile) {
    view = 'smallMobile';
  } else if (isMobileView) {
    view = 'mobile';
  } else if (isSmallView) {
    view = 'small';
  } else if (isMediumView) {
    view = 'medium';
  } else {
    view = 'full';
  }

  return (
    <div 
      style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        background: `url(${TopBannerImage})`,
        backgroundPosition: 'top left',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div style={{
        display: 'flex',
        flex: 6,
        justifyContent: 'flex-end',
        padding: (view === 'smallMobile' || view === 'mobile') ? '20px 10px 0px 20px' : '20px 40px 0px 20px'
      }}>
        <img 
          src={BigAriaImage}
          alt="BigAriaImage"
          style={{
            // height: '100%',
            width: '100%',
            maxWidth: '360px'
          }} 
        />
      </div>
      <div style={{ flex: 9, display: 'flex', alignItems: 'center' }}>
        <div 
          style={{ 
            // flex: 1,
            color: '#ffffff',
            display: 'flex',
            paddingRight: '20px',
            flexDirection: 'column',
            justifyContent: 'center',
            maxWidth: '650px'
          }}
        >
          <div 
            style={{ 
              fontWeight: 'bold',
              fontSize: (
                view === 'smallMobile' ? '22px'
                : view === 'mobile' ? '28px'
                : view === 'small' ? '34px'
                : view === 'medium' ? '48px'
                : '64px'
              ), 
              marginBottom: '20px'
            }}
          >
            {"Hi! I'm Aria!"}
          </div>
          <div 
            style={{
              fontSize: (
                view === 'smallMobile' ? '14px'
                : view === 'mobile' ? '18px'
                : view === 'small' ? '22px'
                : view === 'medium' ? '30px'
                : '40px'
              )
            }}
          >
            {"How can I support you with designing learning experience?"}
          </div>
        </div>
      </div>
    </div>
  )

  /*
   * Keeping old versions around for now for reference
   *
  return (
    <div style={{ 
      position: 'relative',
      maxHeight: '400px',
      overflow: 'hidden'
    }}>
      <img 
        src={TopBannerImage}
        alt="TopBannerImage"
        style={{ 
          width: '100%'
        }} 
      />
      <div 
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          top: needsTopShift ? '-4px' : '0px',
          right: '0px',
          width: '100%',
          height: '100%',
        }}
      >
        <div style={{
          // display: 'flex',
          padding: (view === 'smallMobile' || view === 'mobile') ? '20px 10px 0px 20px' : '20px 40px 0px 20px'
          }}>
            <img 
              src={BigAriaImage}
              alt="BigAriaImage"
              style={{
                height: '100%',
              }} 
            />
        </div>
        <div 
          style={{ 
            color: '#ffffff',
            display: 'flex',
            paddingRight: '20px',
            flexDirection: 'column',
            justifyContent: 'center',
            maxWidth: '650px'
          }}
        >
          <div 
            style={{ 
              fontWeight: 'bold',
              fontSize: (
                view === 'smallMobile' ? '22px'
                : view === 'mobile' ? '28px'
                : view === 'small' ? '34px'
                : view === 'medium' ? '48px'
                : '64px'
              ), 
              marginBottom: '20px'
            }}
          >
            {"Hi! I'm Aria!"}
          </div>
          <div 
            style={{
              fontSize: (
                view === 'smallMobile' ? '14px'
                : view === 'mobile' ? '18px'
                : view === 'small' ? '22px'
                : view === 'medium' ? '30px'
                : '40px'
              )
            }}
          >
            {"How can I support you with designing learning experience?"}
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div style={{ position: 'relative' }}>
      <div>
        <img 
          src={HeroBanner}
          alt="HeroBanner"
          style={{ 
            width: '100%'
          }} 
        />
      </div>
      <div 
        style={{ 
          flex: 1, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'flex-end', 
          position: 'absolute',
          top: '50%',
          right: '0px',
          transform: 'translateY(-50%)'
        }}
      >
        <div 
          style={{ 
            color: '#ffffff',
            width: (
              view === 'smallMobile' ? '160px'
              : view === 'mobile' ? '225px'
              : view === 'small' ? '250px'
              : view === 'medium' ? '450px'
              : '650px'
            ),
            marginTop: (view === 'smallMobile' || view === 'mobile' || view === 'small') ? '-20px' : '0px'
          }}
        >
          <div 
            style={{ 
              fontWeight: 'bold', 
              fontSize: (
                view === 'smallMobile' ? '22px'
                : view === 'mobile' ? '32px'
                : view === 'small' ? '34px'
                : view === 'medium' ? '48px'
                : '64px'
              ), 
              marginBottom: '20px'
            }}
          >
            {"Hi! I'm Aria!"}
          </div>
          <div 
            style={{
              fontSize: (
                view === 'smallMobile' ? '14px'
                : view === 'mobile' ? '20px'
                : view === 'small' ? '22px'
                : view === 'medium' ? '30px'
                : '40px'
              ),
              // width: '650px'
            }}
          >
            {"How can I support you with designing learning experience?"}
          </div>
        </div>
      </div>
    </div>
  )*/
}