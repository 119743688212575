import { useState } from 'react'
import styled from 'styled-components'

export default () => {
  const [isExpanded, setIsExpanded] = useState()

  const UlHex = styled.ul`
    list-style: none;
    padding: 0;
    margin: 20px;

    li { 
      position: relative;
      padding-left: 20px;
      margin-bottom: 10px;
    }

    li:before {
      content: '⬢';
      color: #16B79E;
      font-size: 24px;
      position: absolute;
      left: -10px;
      top: 8px;
      transform: translateY(-50%);
    }
  `

  return (
    <div style={{ padding: '20px', width: '835px' }} >
      <div style={{ textAlign: 'center', textDecoration: 'underline', fontStyle: 'italic', cursor: 'pointer' }} onClick={() => setIsExpanded(!isExpanded)}>
        {`Data and security are important to us! Click here to learn about how we protect your data.`}
      </div>
      <div style={{ display: (isExpanded ? 'block' : 'none'), marginTop: '40px' }}>
        <h3>{`ARIA AI Assistant: Data Security & Protection`}</h3>
        <p>
          {`We understand that security and data protection are critical when using AI tools in education. That's why ARIA, powered by GPT-4o, is designed with your privacy and safety in mind. Here's how we ensure your data is protected:`}
        </p>
        <UlHex>
          <li><strong>{`Encryption:`}</strong>{`All your interactions with ARIA are encrypted and securely stored, ensuring your data remains private.`}</li>
          <li><strong>{`Compliance:`}</strong>{`We adhere to GDPR guidelines to safeguard your data, and we work exclusively with GDPR-compliant partners.`}</li>
          <li><strong>{`Security Practices:`}</strong>{`We use secure OAuth2 mechanisms to protect access, and no sensitive credentials are included in our code.`}</li>
          <li><strong>{`Data Storage:`}</strong>{`Your data is stored safely in Belgium, EU, under strict security protocols to provide an extra layer of protection.`}</li>
          <li><strong>{`Privacy:`}</strong>{`Rest assured, your conversations with ARIA are never used for AI training, ensuring your information remains confidential.`}</li>
        </UlHex>
        <p>
          {`Your trust and security are our top priority. ARIA is here to support your teaching, without compromising your privacy.`}
        </p>
      </div>
    </div>
  )
}