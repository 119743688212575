import React, { useEffect, useState } from 'react';
import { useGlobalData } from '../GlobalData';
import { FaExpand, FaCompress } from 'react-icons/fa';

export default () => {

  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  const [docHeight, setDocHeight] = useState('0px')

  useEffect(() => {
    const setDocumentHeight = () => {
      setDocHeight(`${window.innerHeight - 6}px`)
    }

    // Run initial calc as well
    setDocumentHeight()

    window.addEventListener('resize', setDocumentHeight)

    return () => window.removeEventListener('resize', setDocumentHeight)
  }, [])

  useEffect(() => {

    // Unused for now since the below script creates a badge which opens a model which contains the AI below

    // const script = document.createElement('script');
  
    // script.defer = true;
    // script.src = "https://ps.careerhacks.ai/embed.js";
    // script.setAttribute('data-bot-id', 'clqeilgg8011jppb2wdq5iabx')
  
    // document.body.appendChild(script);
  
    // return () => {
    //   document.body.removeChild(script);
    // }
  }, []);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const onExpandClick = () => {
    setIsFullScreen(!isFullScreen)
  }

  const wrapperStyle = isFullScreen ?
    {
      width: 'calc(100vw - 2px)',
      background: '#ffffff',
      position: 'absolute', 
      top: '0px', 
      left: '0px'
    } :
    {
      borderRadius: '10px', 
      background: '#16B79E', 
      padding: '9px 10px 6px 9px',
      margin: '0px 20px',
      width: '1075px',
      boxSizing: 'border-box',
      position: 'relative'
    };
    

  return (
    <div style={wrapperStyle}>
      <div 
        style={{ 
          position: 'absolute',
          background: '#ffffff',
          padding: '5px',
          borderRadius: '20px',
          top: '12px',
          right: isFullScreen ? '8px' : '12px',
          cursor: 'pointer',
          zIndex: 1000
        }}
        onClick={onExpandClick}
      >
        {
          isFullScreen 
          ?
          <FaCompress style={{ fontSize: '18px', color: '#b1b5c4' }} />
          : 
          <FaExpand style={{ fontSize: '18px', color: '#b1b5c4' }} />
        }
      </div>
      <iframe 
        title='prosolve-ai'
        src="https://ps.careerhacks.ai/embed/clqeilgg8011jppb2wdq5iabx"
        style={{ 
          width: '100%', 
          // height: '800px',
          height: isFullScreen ? docHeight :
                  isMobileView ?  'calc(100vh - 140px)' : 'calc(100vh - 35px)',
          // border: '1px solid #dedede',
          border: '1px solid #969696',
          borderRadius: '5px'
          // border: 'none' 
        }}
      >
      </iframe>
    </div>
  )
}