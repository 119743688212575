import React from 'react';
import { useGlobalData } from '../GlobalData';
import { Main, MainContent, Footer } from '@prosolve/common-components-ui'
import HeaderBlock from './HeaderBlock';
import IntroductionBlock from './IntroductionBlock';
import TipsBlock from './TipsBlock';
import EmbeddedAIComp from './EmbeddedAIComp';
import ExpandLearningSection from './ExpandLearningSection/ExpandLearningSection';
import DisclaimerBlock from './DisclaimerBlock';

export default () => {

  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  return (
    <Main 
      $isMobileView={isMobileView} 
      style={{ 
        padding: '0px', 
        height: 'calc(100vh - 0px)', 
        minHeight: 'none', 
        overflow: 'hidden' 
      }}
    >
      <MainContent 
        $isMobileView={isMobileView} 
        style={{ 
          width: '100%', 
          maxWidth: 'none', 
          height: '100%', 
          background: '#ffffff', 
          borderRadius: '0px',
          color: '#4B4847',
          overflow: 'auto'
        }}
      >
        <HeaderBlock isMobileView={isMobileView} />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <IntroductionBlock />
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          <TipsBlock />
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          <div style={{ 
            fontSize: '20px', 
            padding: '20px',
            width: '835px',
            textAlign: 'left' 
          }}>
            {"To save the ideas that Aria provides below, select the text and copy-paste it to your preferred word processing solution."}
          </div>
        </div>
        
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <EmbeddedAIComp />
        </div>
        

        <ExpandLearningSection />

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '40px' }}>
          <DisclaimerBlock />
        </div>

        <Footer 
          hideTagLine={true}
          style={{
            background: '#16B79E', 
          }} 
        />
        
      </MainContent>
    </Main>
  )
}
