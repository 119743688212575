import React from 'react';
import EmbeddedAI from './EmbeddedAI/EmbeddedAI';
import useMediaQuery from './hooks/useMediaQuery';
import GlobalData, { GlobalDataContext } from './GlobalData'
import Router from './Router';
import '@prosolve/common-components-ui/dist/styles.css'

export default () => {
  const isMobileView = useMediaQuery('(max-width: 450px)');

  const globalData = new GlobalData();
  globalData.setIsMobileView(isMobileView)

  const routes = [
    {
      path: "/",
      isPrivate: true,
      element: (routeProps) => <EmbeddedAI {...routeProps} />,
    }
  ]

  return (
    <GlobalDataContext.Provider value={globalData}>
      <Router routes={routes} />
    </GlobalDataContext.Provider>
  )
}