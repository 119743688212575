import React, { useState } from "react";
import useForm from './hooks/useForm';
import { FormField, Button, LoadingIcon } from '@prosolve/common-components-ui'
import useFetch from './hooks/useFetch';
import TopBannerImage from './images/teal-hex-bkg.png'
import AriaImage from './images/aria standing.png'
import useMediaQuery from './hooks/useMediaQuery';
import { useGlobalData } from './GlobalData';

export default () => {
  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  const isSmallView = useMediaQuery('(max-width: 620px)');

  const fetch = useFetch();

  const [isBusy, setIsBusy] = useState(false);
  const [showInvalidCode, setShowInvalidCode] = useState(false);

  const formSchema = {
    requiredFields: ['code'],
    initData: {
      code: ''
    }
  }

  const formData = useForm(formSchema)

  const onClickSubmit = async () => {
    if (formData.isValid()) {
      setIsBusy(true)

      const response = await fetch(
        `ai/auth?code=${formData.values.code.toUpperCase()}`, 
        { 
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      )

      const jsonData = await response.json() || {};
      const { success = '' } = jsonData;

      if (success) {
        window.location.reload()
      } else {
        setShowInvalidCode(true)
      }

      setIsBusy(false)
    }
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onClickSubmit();
    }
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: isSmallView ? 'column' : 'row',
      rowGap: '15px',
      // padding: '20px',
      height: '100%',
      boxSizing: 'border-box',
      overflow: 'auto',
      overflowX: 'hidden',
      // background: '#ffffff'
      background: `url(${TopBannerImage})  top left/cover no-repeat fixed`
    }}>

      <div style={{display: 'flex', alignItems: 'center', justifyContent: isSmallView ? 'center' : 'flex-end', flex: 3, marginTop: isSmallView ? '20px' : '0px' }}>
        <div style={{
          position:'relative',
          width: '180px',
          height: '433px',
        }}>
          <div style={{
            width: '520px',
            height: '500px',
            overflow: 'hidden',
            position: 'absolute',
            top: '0px',
            left: '-170px'
          }}>
            <img 
              src={AriaImage}
              alt="AriaImage"
              style={{ 
                width: '1200px',
                position: 'absolute',
                top: '-110px',
                left: '-350px'
              }} 
            />
          </div>
        </div>
      </div>

      <div style={{
        flex: 5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: isSmallView ? 'flex-start' : 'center',
        alignItems: 'flex-start',
        padding: '20px',
        zIndex: '100',
        marginTop: isSmallView ? '-15px' : '0px'
      }}>

       <div style={{ display: 'flex', flexDirection: 'column' }}>

          <div style={{ flex: 1, display: 'flex', marginBottom: '20px', textAlign: isSmallView ? 'center' : 'left' }}>
            <div style={{ color: '#ffffff'}}>
              <div style={{ fontWeight: 'bold', fontSize: '40px', marginBottom: '40px'}}>{"Hello!"}</div>
              <div style={{ fontSize: '26px', maxWidth: isSmallView ? 'none' : '600px'}}>
                {"Let's utilize my generative AI to help you create an immersive experiential learning environment for your classroom! Together, we can enhance your student's collaboration, communication, creativity, and critical thinking skills."}
              </div>
            </div>
          </div>

          <FormField
            fieldStyle={{ 
              fontSize: '18px', 
              textAlign: isSmallView ? 'center' : 'left',
              textTransform: 'uppercase'
            }}
            fieldId='code'
            type='text'
            placeholder='Enter your code'
            formData={formData}
            onKeyDown={onKeyDown}
            isMobileView={isMobileView}
          />

          <div style={{ height: '25px', position: 'relative'}}>

          {
            (!isBusy && showInvalidCode) ?
            <div style={{
              color: '#ef0c0c',
              fontStyle: 'italic',
              fontSize: '14px',
              position: 'absolute',
              top: '3px',
              left: '3px'
            }}>
              {'Invalid Code'}
            </div>
            :
            <div>&nbsp;</div>
          }

          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: isSmallView ? 'center' : 'flex-start' }}>
            <Button 
              style={{ 
                display: 'inline-flex', 
                marginRight: '10px', 
                fontWeight: 'bold', 
                fontSize: '24px',
                width: '150px', 
                height: '40px',
                background: 'transparent'
              }} 
              onClick={onClickSubmit} 
              $colored={true}
            >
                {'SUBMIT'}
            </Button>
            {
              isBusy &&
              <LoadingIcon />
            }
          </div>

          <div style={{ display: 'flex', justifyContent: isSmallView ? 'center' : 'flex-start', marginTop: '40px', color: '#ffffff', fontSize: '16px', fontWeight: 'bold' }}>
            <div style={{ marginRight: '10px' }}>{"Don't have a code?"}</div>
            <div
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => window.open('https://www.prosolve.com/aria', '_blank')}
            >
              {"Request access here."}
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}