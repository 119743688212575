import React from 'react';
import useMediaQuery from '../hooks/useMediaQuery';

export default () => {

  const isSmallView = useMediaQuery('(max-width: 500px)');

  return (
    <div 
      style={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px', 
        background: '#16B79E', 
        color: '#ffffff',
        padding: '20px',
        margin: '0px 20px',
        maxWidth: '800px',
        overflow: 'hidden'
      }}
    >
      <div style={{ fontWeight: 'bold', fontSize: '32px', marginBottom: '20px', textAlign: 'center' }}>
        {"Aria's Tips for Getting Started:"}
      </div>
      <div style={{ display: 'flex', flexDirection: isSmallView ? 'column' : 'row', rowGap: '20px', columnGap: '20px', fontSize: '20px' }}>
        <div style={{ flex: 1}}>{"Start by sharing the grade level, learning goals, topic, or standards. "}</div>
        <div style={{ flex: 1}}>{"Continue to ask several questions. The more I know, the better I can guide you."}</div>
        <div style={{ flex: 1}}>{"Give me feedback! If I miss something or you need more details, just let me know."}</div>
        <div style={{ flex: 1}}>{"Refresh or open a new tab whenever you want to discuss a new topic."}</div>
      </div>
    </div>
  )
}