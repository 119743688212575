import config from '../config.json';
export default () => {
    const host = window.location.host;
    const domain = 'prosolve.com';
    const subDomain = 'ai';
    const apiSubDomain = 'api';

    let apiBaseUrl;
    if (host.indexOf('localhost') > -1) {
        apiBaseUrl = `http://localhost:${config.port}/api`;
    } else {
        // detect env
        const parts = host.split('.');
        // Look backward to always get part prior to 'prosolve.com'
        const envPart = parts[parts.length-3];
        const env = envPart !== subDomain ? `.${envPart}` : '';
        apiBaseUrl = `https://${apiSubDomain}.${subDomain}${env}.${domain}/api`
    }
    
    return async (url, ...args) => {
        return await fetch(`${apiBaseUrl}/${url}`, ...args)
    }
};