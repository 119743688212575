import React from 'react';
import { Section } from '@prosolve/common-components-ui'
import useMediaQuery from '../../hooks/useMediaQuery';
import ModelImage from '../../images/ProSolve Learning Experienced Model.png'
import QAndAAccordion from './QAndAAccordion';
import { useGlobalData } from '../../GlobalData';

export default () => {
  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  const isSmallView = useMediaQuery('(max-width: 850px)');

  return (
    <Section style={{ borderRadius: '0px', marginTop: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div 
          style={{
            color: '#16B69E',
            fontWeight: 'bold',
            fontSize: '36px',
            width: '700px',
            textAlign: 'center'
          }}
        >
          {'Learn more about the LXP Framework and how to use my generative AI model.'}
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', margin: '30px 0px' }}>
        <img 
          src={ModelImage}
          alt="LearningExperiencedModelImage"
          style={{ 
            width: isMobileView ? '100%' : '445px'
          }} 
        />
      </div>
      <div style={{ display: 'flex', flexDirection: isSmallView ? 'column' : 'row', rowGap: '20px', columnGap: '20px', alignItems: 'flex-start', justifyContent: 'center', marginBottom: '20px' }}>
        <div style={{ width: isSmallView ? '100%' : 'auto' }}>
          <QAndAAccordion
            title='Immersive Experiences'
            isSmallView={isSmallView}
            data={[
              {
                question: 'How it Works', 
                answers: [
                  "Simulated or real-world problem-solving experiences",
                  "Interactive activities using purposeful play, gamification, role-playing, and project-based learning"
                ]
              },
              {
                question: 'Why it Matters', 
                answers: [
                  "Create an atmosphere of creativity, exploration, and experimentation ",
                  "Whole child development by fostering integral soft skills, emotional intelligence, and 4Cs"
                ]
              },
              {
                question: 'Conversation Starters', 
                answers: [
                  "\"Design a real-world or simulated scenario or story.\"",
                  "\"Generate ideas to incorporate games, puzzles, or escape rooms.\"",
                  "\"Give me an idea for a role-playing scenario representing the real world.\"",
                  "\"Write a story for me to tell students and include the narrative.\""
                ]
              },
            ]}
          />
        </div>
        <div style={{ width: isSmallView ? '100%' : 'auto' }}>
          <QAndAAccordion
            title='Inquiry Through Exploration'
            isSmallView={isSmallView}
            data={[
              {
                question: 'How it Works', 
                answers: [
                  "Students ask open-ended questions, discover through inquiry, and engage deeply with content",
                  "Develops AI Literacy through the “Find, Filter, and Apply” approach",
                ]
              },
              {
                question: 'Why it Matters', 
                answers: [
                  "Builds natural curiosity, exploration, and discovery",
                  "Recognizes there are multiple pathways for understanding; there are no right or wrong answers to learning",
                ]
              },
              {
                question: 'Conversation Starters', 
                answers: [
                  "\"Generate questions that can’t be Googled.\"",
                  "\"Write a Find, Filter, Apply lesson.\"",
                  "\"Design an inquiry-based learning experience.\""
                ]
              }
            ]}
          />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: isSmallView ? 'column' : 'row', rowGap: '20px', columnGap: '20px', alignItems: 'flex-start', justifyContent: 'center' }}>
        <div style={{ width: isSmallView ? '100%' : 'auto' }}>
          <QAndAAccordion
            title='Authentic Application'
            isSmallView={isSmallView}
            data={[
              {
                question: 'How it Works', 
                answers: [
                  "Teams work collaboratively to tackle complex problems that reflect real-world work environments",
                  "Measure learning through performance-based assessments",
                ]
              },
              {
                question: 'Why it Matters', 
                answers: [
                  "Application of practical knowledge through real-world contexts",
                  "Bridges the gap between theoretical study and active, project-based learning",
                ]
              },
              {
                question: 'Conversation Starters', 
                answers: [
                  "\"Design a performance task and rubric.\"",
                  "\"Generate examples of real-world examples about my content.\"",
                  "\"Create an authentic audience, task, product, and scenario.\""
                ]
              }
            ]}
          />
        </div>
        <div style={{ width: isSmallView ? '100%' : 'auto' }}>
          <QAndAAccordion
            title='Reflection with Transfer'
            isSmallView={isSmallView}
            data={[
              {
                question: 'How it Works', 
                answers: [
                  "Students reflecting and applying learning across all experiences",
                  "Guide students in metacognition, “Thinking about thinking,” to identify patterns, relationships, and connections among concepts to then transfer into new situations",
                ]
              },
              {
                question: 'Why it Matters', 
                answers: [
                  "Moves from recall to analysis, evaluation, and synthesis for deeper learning",
                  "Transfer of knowledge and skills to real-life situations",
                ]
              },
              {
                question: 'Conversation Starters', 
                answers: [
                  "\"Write reflection questions for discussion.\"",
                  "\"Identify practical ways students can use this content and skills in real-world situations.\"",
                  "\"Generate a self-assessment for student reflection.\"",
                ]
              }
            ]}
          />
        </div>
      </div>
    </Section>
  )
}